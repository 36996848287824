.dx-texteditor-input-container{
    // padding: 15px;
}

.section-urls{
    display: flex;
    flex-direction: column;
    a{
        margin-bottom: 8px;
    }
}

.section-box{
    margin-right: 10px;
    // padding: 15px;
    padding: 0 15px 10px;
}