.control-buttons{
    display: flex;
    .control-button{
        margin-right: 8px;
    }
    
}

.section-box{
    padding: 8px;
}

.to-end{
    justify-content: flex-end;
}

.section-urls{
    a{
        word-wrap: break-word;
    }
}

.time-hint{
    padding-top: 0 !important;
    padding-left: 8px !important;
}
.service-item{
    display: flex;
    .dx-icon-help{
        font-size: 24px;
        margin-left: 8px;
        color: gray;
        cursor: pointer;
        &:hover{
            color: black;
        }
    }
}
