.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.user-drop{
  margin-bottom: 15px;
  max-width: 350px
}

.summary-blocks{
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 20px;
  .dx-card{
    padding-bottom: 30px;
    flex-grow: 1;
    margin: 8px;
    text-align: center;
    .stats{
      display: flex;
      .stat{
        width: 60%;
        text-align: right;
        padding-right: 8px;
        font-weight: 900;
      }
      .value{
        width: 40%;
        text-align-last: left;
      }
    }
  }
}